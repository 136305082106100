/*
TE Client
*/

window.__tebootstrap = window._teboostrap || {};

window.__tebootstrap.TrackEverything = function(window) {
  // track everything object
  var TrackEverything = function() {
    this.backup = window.te && window.te.slice ? window.te.slice(0) : [];
    this.XMLHttpFactories = [
      function() {
        return new window.XDomainRequest();
      },
      function() {
        return new window.XMLHttpRequest();
      },
      function() {
        return new window.ActiveXObject("Msxml2.XMLHTTP");
      },
      function() {
        return new window.ActiveXObject("Msxml3.XMLHTTP");
      },
      function() {
        return new window.ActiveXObject("Microsoft.XMLHTTP");
      }
    ];

    this.eventList = [];

    this.created = false;
    this.init();
  };

  TrackEverything.prototype = [];

  TrackEverything.prototype.init = function() {
    this.cookieHost = window.location.host.replace(/^[^.]*/g, "");
    this.baseUrl =
      "//" + window.location.hostname.replace(/^[^.]*/g, "track") + "/api";
    this.LSKey = "te.backup";

    for (var i = this.backup.length - 1; i >= 0; i--) {
      if (this.backup[i][0] === "create") {
        this.create(this.backup.splice(i, 1)[0]);
        break;
      }
    }
  };

  TrackEverything.prototype.create = function(create) {
    var client = create[1],
      uuid = create[2],
      userid = create[3],
      useStaging = create[4] === "useStaging" ? true : false;

    // ios specific format
    if (userid === "(null)") {
      userid = null;
    }

    if (typeof userid === "string") {
      userid = parseInt(userid, 10);
      // typeof NaN is a number
    }

    if ("android" !== client && "ios" !== client && "mobile-web" !== client) {
      client = "web";
    }

    if (typeof uuid !== "string" || "" === uuid) {
      this.getUUID(client, userid);
      return false;
    }

    if ((userid !== null && typeof userid !== "number") || isNaN(userid)) {
      this.log(
        "improper create, userid must be a number or null",
        true,
        create[3]
      );
      return false;
    }

    if (process.env.NODE_ENV === "development") {
      this.isDev = true;
    } else if (process.env.NODE_ENV === "staging") {
      this.useStaging = true;
    }

    this.client = client;

    //Character limit with backend is 256, trimming to 200 to be safe and logging instances in case its a
    //client app issue and not someone exploring/crawling our open APIs
    // typically expected format is UUIDv4 type 00000000-0000-0000-0000-000000000000
    if (typeof uuid == "string" && uuid.length > 255) {
      console.error(
        `abnormal UUID found while initialising the tracker app. Trimming the UUID to 255 chars. UserId (if available): ${userid}`
      );
      uuid = uuid.substring(0, 200);
    }

    this.uuid = uuid;
    this.userid = userid;
    this.useStaging = useStaging;

    if (this.isSpecialBrowser(window.navigator.userAgent) === true) {
      this.baseUrl = "/v4/prometheus";
    }

    if (this.useStaging === true) {
      this.baseUrl = "//track.wattpad-staging.com/api";
    }

    if (this.isDev === true) {
      this.client = this.client + "-dev";
    }

    this.created = true;
    this.restorePreviousSession();
    this.processBackup();
  };

  TrackEverything.prototype.processBackup = function() {
    for (var j = 0; j < this.backup.length; j++) {
      this.push.apply(this, this.backup[j]);
    }
  };

  TrackEverything.prototype.push = function() {
    if (this.created && arguments[0] === "event") {
      // if the item pushed is an event
      var timestamp,
        name,
        details,
        useGet = false,
        lastIndex = arguments.length - 1;

      if (typeof arguments[lastIndex] === "object") {
        var last = arguments[lastIndex];

        if (last.ts) {
          // if timestamp is in event.details, get it and remove it from event.details
          timestamp = last.ts;
          delete last.ts;
        }

        if (last.useGet) {
          // if useGet is in event.details, get it and remove it from event.details
          useGet = true;
          delete last.useGet;
        }

        if (Object.keys(last).length === 0) {
          // if optional args were the only things provided to event.details,
          // delete event.details and update last index
          delete arguments[lastIndex];
          lastIndex--;
        }
      }

      timestamp = timestamp || 1 * new Date();

      // last may change, so do this check twice
      if (
        typeof arguments[lastIndex] === "object" ||
        arguments[lastIndex] === void 0
      ) {
        // sometimes we have an undefined variable on the end instead of an empty object
        // if event.details exists - last argument is an object
        // join all but event.details and the first argument --'event'-- as the event name
        name = Array.prototype.join.call(
          Array.prototype.slice.call(arguments, 1, -1),
          ":"
        );
        details = arguments[lastIndex];
      } else {
        // if event.details does NOT exist - last argument is not an object
        // join all but the first argument --'event'-- as the event name
        name = Array.prototype.join.call(
          Array.prototype.slice.call(arguments, 1, lastIndex + 1),
          ":"
        );
        details = null;
      }

      Array.prototype.push.call(this, {
        name: name,
        timestamp: timestamp,
        details: details,
        useGet: useGet ? true : void 0
      });
      this.onPush();
    } else if (!this.created) {
      // if the item pushed is a not an event

      if (arguments[0] === "create") {
        this.create(arguments);
      } else {
        this.backup.push(arguments);
      }
    } else {
      this.log("pushing an undefined event");
      return false;
    }
  };

  // callback for TrackEverything.push
  TrackEverything.prototype.onPush = function() {
    var eventList = [],
      item;

    while ((item = this.shift()) !== undefined) {
      if (item.useGet) {
        // if we're using a get request, send it individually
        this.sendEvent(
          [
            {
              name: [this.client, item.name].join(":"),
              uuid: this.uuid,
              userid: this.userid,
              timestamp: item.timestamp,
              session_id: this.getSessionId(),
              details: item.details
            }
          ],
          true
        );
        continue;
      }

      eventList.push({
        name: [this.client, item.name].join(":"),
        uuid: this.uuid,
        userid: this.userid,
        timestamp: item.timestamp,
        session_id: this.getSessionId(),
        details: item.details
      });
    }

    if (eventList.length === 0) {
      return;
    }

    this.addEvent(eventList);
  };

  TrackEverything.prototype.send = function(type, data) {
    var xhrObj = this.getxhrObject(),
      endpoint = this.baseUrl + "/event";

    if (!xhrObj) {
      return false;
    }
    if (type === "error") {
      endpoint = this.baseUrl + "/error";
    }

    xhrObj.open("post", endpoint, true);

    // XDomainRequest doesn't support setting Content-Type.
    try {
      xhrObj.setRequestHeader("Content-Type", "application/json");
    } catch (e) {}

    if (JSON) {
      xhrObj.send(JSON.stringify(data));
    }
  };

  TrackEverything.prototype.sendGet = function(event, timestamp) {
    var xhrObj = this.getxhrObject(),
      endpoint = this.baseUrl + "/event/_wts.gif";

    if (!xhrObj) {
      return false;
    }

    endpoint += this.urlEncodeData(event, timestamp);

    xhrObj.open("get", endpoint, true);
    xhrObj.send();
  };

  TrackEverything.prototype.sendEvent = function(eventArray, useGet) {
    if (useGet) {
      this.sendGet(eventArray[0], 1 * new Date());
      return;
    }

    this.send("event", { events: eventArray, timestamp: 1 * new Date() });
  };

  TrackEverything.prototype.sendError = function(msg, data) {
    this.send("error", { message: msg, data: data });
  };

  TrackEverything.prototype.getUUID = function(client, userid) {
    // check for uuid in cookie
    if (window.document.cookie.match(/wp_id=([^;]+)/)) {
      this.create([
        "create",
        client,
        window.document.cookie.match(/wp_id=([^;]+)/)[1],
        userid
      ]);
      return true;
    }

    var xhrObj = this.getxhrObject(),
      self = this,
      endpoint = this.baseUrl + "/uuid";

    if (!xhrObj) {
      return false;
    }

    xhrObj.open("get", endpoint, true);
    xhrObj.send();

    xhrObj.onreadystatechange = function() {
      if (xhrObj.readyState === 4 && xhrObj.status === 200) {
        var result = JSON.parse(xhrObj.responseText);
        window.document.cookie =
          "wp_id=" +
          result.uuid +
          ";expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=.wattpad.com;path=/;SameSite=Lax;Secure;";
        self.create(["create", client, result.uuid, userid]);
      }
    };
  };

  TrackEverything.prototype.getxhrObject = function() {
    var xmlhttp = false;

    for (var i = 0; i < this.XMLHttpFactories.length; i++) {
      try {
        xmlhttp = this.XMLHttpFactories[i]();
      } catch (e) {
        continue;
      }
      break;
    }

    return xmlhttp;
  };

  TrackEverything.prototype.log = function(msg, send, data) {
    if (window.console && window.console.log) {
      window.console.log(msg);
    }

    if (send) {
      this.sendError(msg, data);
    }
  };

  TrackEverything.prototype.urlEncodeData = function(evt, timestamp) {
    var qs = "?timestamp=" + timestamp;

    for (var key in evt) {
      if (evt.hasOwnProperty(key)) {
        // add named properties to qs
        if (key === "name") {
          qs += "&name=" + evt.name;
        } else if (key === "uuid") {
          qs += "&uuid=" + evt.uuid;
        } else if (key === "userid") {
          qs += "&userid=" + evt.userid;
        } else if (key === "details") {
          // add details to qs as the individual details themselves
          for (var detail in evt.details) {
            if (evt.details.hasOwnProperty(detail)) {
              qs += "&" + detail + "=" + evt.details[detail];
            }
          }
        }
      }
    }
    return (qs += "&now=" + 1 * new Date());
  };

  TrackEverything.prototype.isSpecialBrowser = function(str) {
    return (
      Boolean(str.match(/(Opera Mini)/)) ||
      Boolean(str.match(/(MSIE 8.+)/)) ||
      Boolean(str.match(/(MSIE 9.+ IEMobile)/)) ||
      Boolean(str.match(/(MSIE 7.+ IEMobile)/))
    );
  };

  TrackEverything.prototype.addEvent = function(newEvents) {
    this.eventList = this.eventList.concat(newEvents);

    // Write the current eventList to the LocalStorage cache in case the user leaves the site before they can be sent
    try {
      window.localStorage.setItem(this.LSKey, JSON.stringify(this.eventList));
    } catch (e) {
      // fail silently
    }

    // Below previously was set to 10, reverted to 1 to effectively disable batching while keeping the batching code in place
    if (this.eventList.length >= 1) {
      this.processBatch();
    } else {
      if (!this.batchTimer) {
        this.batchTimer = window.setTimeout(
          this.processBatch.bind(this),
          60000
        );
      }
    }
  };

  TrackEverything.prototype.processBatch = function() {
    if (this.eventList.length) {
      this.sendEvent(this.eventList);
    }

    this.eventList = [];

    if (this.batchTimer) {
      window.clearTimeout(this.batchTimer);
      this.batchTimer = null;
    }
    // Clear the localStorage cache now that the eventList has been sent
    try {
      window.localStorage.removeItem(this.LSKey);
    } catch (e) {
      // fail silently
    }
  };

  TrackEverything.prototype.restorePreviousSession = function() {
    var previousSessionEvents;
    try {
      previousSessionEvents = JSON.parse(
        window.localStorage.getItem(this.LSKey)
      );
    } catch (e) {
      // do nothing, assume no previous session data exists or isn't readable anyway
    }

    if (previousSessionEvents && previousSessionEvents.length) {
      // Temporarily sending an additional event so we can get a sense of how many events we're restoring from previous sessions
      previousSessionEvents.push({
        name: [this.client, "tracker:::restore_session"].join(":"),
        uuid: this.uuid,
        userid: this.userid,
        timestamp: 1 * Date.now(),
        session_id: this.getSessionId(),
        details: { queue_size: previousSessionEvents.length }
      });

      this.eventList = this.eventList.concat(previousSessionEvents);
      this.processBatch();
    }
  };

  TrackEverything.prototype.getSessionId = function() {
    var session_id;

    if (this.sessionIdTimeout) {
      window.clearTimeout(this.sessionIdTimeout);
    }

    this.sessionIdTimeout = window.setTimeout(this.clearSessionId, 900000); // 900000ms = 15 minutes

    // first attempt to recover the session_id from a session cookie (ie: another browser tab)
    // if not found, generate a new one (timestamp is fine for our purposes when combined with other fields like uuid)
    if (window.document.cookie.match(/te_session_id=([^;]+)/)) {
      session_id = parseInt(
        window.document.cookie.match(/te_session_id=([^;]+)/)[1]
      );
    } else {
      session_id = 1 * Date.now();
    }

    window.document.cookie =
      "te_session_id=" +
      session_id +
      ";domain=" +
      this.cookieHost +
      ";path=/;SameSite=Lax;Secure;";

    return session_id;
  };

  TrackEverything.prototype.clearSessionId = function() {
    // make sure the cookie is cleared by setting as empty and expiring in past
    window.document.cookie =
      "te_session_id=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" +
      this.cookieHost +
      "; path=/;SameSite=Lax;Secure;";
  };

  TrackEverything.prototype.getPage = function() {
    // Event specification mapping for `page`
    // [] is used to differentiate paths such as `/search` and `/search/love`
    // i.e. [ 'value-for-search', 'value-for-search-love' ]
    var eventSpecPageMapping = {
      stories: "category",
      myworks: ["create", "create_story_details"],
      forgot: "forgot_password",
      home: "home",
      "invite-friends": "invite_friends",
      archive: "library",
      library: "library",
      login: "sign_in",
      notifications: "notifications",
      user: "profile",
      reading: "reading", // See code below for handling story reading paths
      list: ["reading_list", "reading_list_details"],
      search: ["search", "search_results"],
      "": "sign_up",
      signup: "sign_up",
      story: "story_details",
      tags: "tag_page"
    };

    // "/tags/doge" = [ "", "tags", "doge" ]
    var pathArray = window.location.pathname.split("/"),
      mainPathName = pathArray[1];

    // Edge case: story reading path format is /:storyPartId-:storyPartTitle
    // i.e. /417447421-destin-copyright-info
    if (mainPathName.match(/^\d+/)) {
      mainPathName = "reading";
    }

    var pageMapping = eventSpecPageMapping[mainPathName];

    // Handling [] values
    if (pageMapping && typeof pageMapping === "object") {
      if (pathArray[2] && pathArray[2].length > 0) {
        pageMapping = pageMapping[1];
      } else {
        pageMapping = pageMapping[0];
      }
    }

    return pageMapping || mainPathName;
  };

  return TrackEverything;
};

window.__tebootstrap.start = function(window) {
  var TrackEverything = window.__tebootstrap.TrackEverything(window);
  window.te = new TrackEverything();
};

window.__tebootstrap.start(window);
